import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-feature-block",
  templateUrl: "./feature-block.component.html"
})
export class FeatureBlockComponent implements OnInit {
  @Input() feature: {
    id: number;   
    title: string;
    description: string;
    image: string;
  };
  basePath: string =  '/../../assets/images/banner-images/';
  constructor() {}

  ngOnInit() {}

  getBackgroundImageUrl(imageName: string): string {    
    return `url(${this.basePath}/${imageName}) no-repeat`;
  }
}
