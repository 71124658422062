import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { OffersRoutingModule } from "./offers-routing.module";
import { OffersPageComponent } from "./offers-page/offers-page.component";
import { OffersImageBlockComponent } from "./offers-image-block/offers-image-block.component";

@NgModule({
  declarations: [OffersPageComponent, OffersImageBlockComponent],
  imports: [CommonModule, OffersRoutingModule],
})
export class OffersModule {}
