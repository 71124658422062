import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'app-home-item',
  templateUrl: './home-item.component.html',
  styleUrls: ['./home-item.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
    ]),
    trigger('slideIn', [
      state('in', style({ transform: 'translateX(0)' })),
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('500ms', style({ transform: 'translateX(0)' })),
      ]),
    ]),
  ],
})
export class HomeItemComponent implements OnInit {
  @Input() homeitem: {
    id: number;
    name: string;
    title: string;
    tagline: string;
    buttontext: string;
    buttonlink: string;
    image: string;   
  };
  direction: string;

  basePath: string =  '/../../assets/images/banner-images/';
  constructor() {
    this.direction = '0'; // Valor inicial

    if (this.homeitem && this.homeitem.id % 2 !== 0) {
      this.direction = '-100%'; // Transición de izquierda a derecha
    } else if (this.homeitem && this.homeitem.id % 2 === 0) {
      this.direction = '100%'; // Transición de derecha a izquierda
    }
  }

  ngOnInit() {}

  getBackgroundImageUrl(imageName: string): string {    
    return `url(${this.basePath}/${imageName}) no-repeat`;
  }

}
