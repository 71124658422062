import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutRoutingModule } from './about-routing.module';
import { AboutPageComponent } from './about-page/about-page.component';
import { FeatureBlockComponent } from './feature-block/feature-block.component';
import { SharedContactFormModule } from '../shared/sharedContactForm.module';
import { SharedAboutPageModule } from '../shared/shared-about-page.module';
import { SharedFeatureBlockModule } from '../shared/shared-feature-block.module';
import { AboutMainPageComponent } from './about-main-page/about-main-page.component';


@NgModule({
  declarations: [        
  
    AboutMainPageComponent
  ],
  imports: [
    CommonModule,
    AboutRoutingModule,
    // SharedContactFormModule,
   // SharedAboutPageModule,
    SharedFeatureBlockModule
  ]
})
export class AboutModule { }
