import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutRoutingModule } from '../about/about-routing.module';
import { FormsModule } from '@angular/forms';
import { FeatureBlockComponent } from '../about/feature-block/feature-block.component';


@NgModule({
  declarations: [FeatureBlockComponent],
  exports: [FeatureBlockComponent],
  imports: [CommonModule, AboutRoutingModule, FormsModule],
})
export class SharedFeatureBlockModule { }
