import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { HomeRoutingModule } from "./home-routing.module";
import { HomePageComponent } from "./home-page/home-page.component";
import { HomeItemComponent } from './home-item/home-item.component';
import { ContactFormModule } from "../contact-form/contact-form.module";
import { SharedContactFormModule } from "../shared/sharedContactForm.module";
import { HomeinformationComponent } from "../homeinformation/homeinformation.component";
import { AboutUsComponent } from "../about-us/about-us.component";
// import { ContactFormComponent } from "../contact-form/contact-form.component";

@NgModule({
  declarations: [HomePageComponent, HomeItemComponent, HomeinformationComponent, AboutUsComponent],
  imports: [CommonModule, HomeRoutingModule, SharedContactFormModule],
})
export class HomeModule {}
