import { Component } from '@angular/core';

@Component({
  selector: 'app-homeinformation',
  templateUrl: './homeinformation.component.html',
  styleUrls: ['./homeinformation.component.css']
})
export class HomeinformationComponent {

}
