import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "../../shared/services/config.service";
import { ContactFormComponent } from "../../contact-form/contact-form.component";
import { HomeinformationComponent } from "src/app/homeinformation/homeinformation.component";
import { AboutUsComponent } from '../../about-us/about-us.component';


@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html"
})
export class HomePageComponent implements OnInit {
  // header: {
  //   id: number;
  //   name: string;
  //   tagline: string;
  //   title: string;
  //   description: string;
  // }[];
  homeitems$: Observable<any>;  

  constructor(private config: ConfigService) {}

  scrollToContact(event) {
      
    // Obtener la posición vertical del elemento con el ID "contact"
    const contactElement = document.getElementById("contact");
    const contactPosition = contactElement.offsetTop;
  
    // Desplazarse hasta la posición del elemento con animación suave
    window.scrollTo({
      top: contactPosition,
      behavior: "smooth"
    });
  }

  ngOnInit() {
    this.getPageData("homeitems");
  }

  getPageData(database: string): void {
    this.homeitems$ = this.config.getSettings(database);    
  }
}
