import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" }, 
  {
    path: "home",
    loadChildren: () =>
      //import("./home/home.module").then((mod) => mod.HomeModule),
    import("./test-home/test-home.module").then((mod) => mod.TestHomeModule),
  },
  /* {
    path: "test-home",
    loadChildren: () =>
      import("./test-home/test-home.module").then((mod) => mod.TestHomeModule),
  }, */
   {
    path: "capilar",
    loadChildren: () =>
      import("./services/services.module").then((mod) => mod.ServicesModule),
  },
  {
    path: "tratamientos-capilares",
    loadChildren: () =>
    import("./tratamientos-capilares/tratamientos-capilares.module").then((mod) => mod.TratamientosCapilaresModule),
  }, 
  {
    path: "medicina-estetica",
    loadChildren: () =>
      import("./tratamientos-estetica/tratamientos-estetica.module").then((mod) => mod.TratamientosEsteticaModule),
  }, 
  
  {
    path: "testimonials",
    loadChildren: () =>
      import("./testimonial/testimonial.module").then(
        (mod) => mod.TestimonialModule
      ),
  },
  // {
  //   path: "gallery",
  //   loadChildren: () =>
  //     import("./gallery/gallery.module").then((mod) => mod.GalleryModule),
  // },
  // {
  //   path: "offers",
  //   loadChildren: () =>
  //     import("./offers/offers.module").then((mod) => mod.OffersModule),
  // },
  {
    path: "clients",
    loadChildren: () =>
      import("./clients/clients.module").then((mod) => mod.ClientsModule),
  },
  // {
  //   path: "pricing",
  //   loadChildren: () =>
  //     import("./pricing/pricing.module").then((mod) => mod.PricingModule),
  // },
  {
    path: "contact",
    loadChildren: () =>
      import("./contact-form/contact-form.module").then((mod) => mod.ContactFormModule),
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./privacy-policy/privacy-policy.module").then((mod) => mod.PrivacyPolicyModule),
  },
  {
    path: "legal-notice",
    loadChildren: () =>
      import("./legal-notice/legal-notice.module").then((mod) => mod.LegalNoticeModule),
  },
  // {
  //   path: "404",
  //   loadChildren: () =>
  //     import("./notfound/notfound.module").then((mod) => mod.NotfoundModule),
  // },
   { path: "**", redirectTo: "home" },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
