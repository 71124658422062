import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactFormComponent } from './contact-form.component';
import { ContactFormRoutingModule } from './contact-form-routing.module';
import { FormsModule } from '@angular/forms';
import { SharedContactFormModule } from '../shared/sharedContactForm.module';


@NgModule({
  declarations: [],
  imports: [CommonModule, ContactFormRoutingModule, FormsModule, SharedContactFormModule],
})

export class ContactFormModule { }


