import { Injectable } from "@angular/core";
import { InMemoryDbService } from "angular-in-memory-web-api";

@Injectable({
  providedIn: "root",
})
export class InMemoryDataService implements InMemoryDbService {
  constructor() {}
  createDb() {
    const pages = [
      {
        id: 1,
        name: "intro",
        tagline: "Tratamientos capilares",
        title: "Tratamientos capilares",
        description:
          "StetiCap tiene el placer de ofrecer todos los tratamientos capilares del mercado con una amplia gama de productos de alta calidad, para cuidar tu salud y asegurar tu bienestar.",
      },
      {
        id: 2,
        name: "clients",
        tagline: "Confianza",
        title: "Nuestos clientes",
        description:
          "En nuestra clínica, entendemos que cada persona es única y tiene necesidades y objetivos diferentes cuando se trata de su belleza y bienestar. Por eso, trabajamos de cerca contigo para crear un plan personalizado que se adapte perfectamente a tus necesidades y te ayude a alcanzar tus metas estéticas. Ya sea que desees rejuvenecer tu piel, reducir la apariencia de arrugas y líneas finas, eliminar el vello no deseado o mejorar la forma y contorno de tu cuerpo, estamos aquí para ayudarte.",
      },
      {
        id: 3,
        name: "services",
        tagline: "Creemos que",
        title: "¿Quieres recuperar tu pelo?",
        description:
          "La solución que has estado buscando de forma definitiva se encuentra al alcance de tus manos. Ofrece resultados naturales, seguros y de larga duración.",
      },
      {
        id: 4,
        name: "testimonials",
        tagline: "",
        title: "Testimonios de nuestros pacientes",
        description: "",
      },
      {
        id: 5,
        name: "pricing",
        tagline: "Tu eleccón",
        title: "Precios especiales adaptados a ti",
        description: "",
      },
      {
        id: 6,
        name: "footer",
        tagline: "Desarrollado por",
        developer: "Alejandro M. Pi Cano.  Copyright 2023",
        developerlink: "https://www.linkedin.com/in/alejandro-m-pi-cano/",
      },
      {
        id: 7,
        name: "header",
        tagline: "Centro médico especializado en trasplante capilar",
        title:
        "Somos una clínica especializada en injerto capilar y medicina estética en Madrid. Nuestro equipo altamente capacitado ofrece soluciones efectivas para el cuidado corporal y la restauración capilar. ¡Recupera tu cabello y rejuvenece tu apariencia hoy mismo!",
        buttontext: "Descúbrenos",
        buttonlink: "/home",
        image: "banner-image-1.jpg",
      },
      {
        id: 8,
        name: "header",
        tagline: "Medicina estética",
        title: "Medicina estética",
        description: "Somos una clínica especializada en <strong> medicina estética </strong>, dedicada a ayudar a nuestros pacientes a alcanzar su mejor versión. Nuestro equipo de profesionales altamente capacitados está comprometido en brindar tratamientos de alta calidad que combinan la ciencia médica con la belleza estética. Nuestro enfoque se basa en escuchar las necesidades individuales de cada persona y desarrollar un plan personalizado que cumpla con sus expectativas. En nuestra clínica, la seguridad y el bienestar de nuestros pacientes son nuestra máxima prioridad, por lo que trabajamos con las técnicas y tecnologías más avanzadas del campo de la medicina estética.",       
      },
    ];
    const homeitems = [
     /* {
        id: 0,
        name: "promobanner",
        tagline: "",
        title:
        "",
        hiddetext:true,
        buttontext: "",
        buttonlink: "",
        image: "home_banner_4.gif",
        backgroundsize: "contain",
        elementid: "promobanner"
      },*/
      {
        id: 1,
        name: "capilar",
        tagline: "Recupera tu cabello, aumenta tu confianza.",
        title:
        "Nuestro equipo médico avalado con años de experiencia y altamente capacitado hará que te veas unos años más joven. Déjate cambiar, sé el cambio.",
        buttontext: "Descúbrenos",
        buttonlink: "/capilar",
        image: "home_banner_1.png",
      },
      {
        id: 2,
        name: "medicina-estetica",
        tagline: "Sé tu mejor versión.",
        title:
        "Descubre cómo nuestra medicina estética transforma y realza tu belleza y tu confianza.",
        buttontext: "Descúbrenos",
        buttonlink: "/medicina-estetica",
        image: "home_banner_3.png",
      },
      {
        id: 3,
        name: "tratamientos-capilares",
        tagline: "Revitaliza tu cabello, despierta su belleza interior.",
        title:
        "Descubre el poder de nuestros tratamientos capilares y transforma tu cabello de manera sorprendente.",
        buttontext: "Descúbrenos",
        buttonlink: "/tratamientos-capilares",
        image: "home_banner_2.png",
      },
   
  
      {
        id: 4,
        name: "testimonials",
        tagline: "¿Quiénes somos?",
        title:
        "Somos una clínica especializada en trasplante capilar y medicina estética. Nuestro equipo, altamente cualificado, ofrece soluciones personalizadas a cada paciente. ¡Mejora tu imagen , siéntete bien!",
        buttontext: "Opiniones",
        buttonlink: "/testimonials",
        image: "equipo_injerto_capilar_ciudad_real.jpg",
      },
    ];
    const featuresEstetica = [
      {
        id: 1,        
        title: "Ácido Hialurónico",  
        alt: "Ácido Hialurónico Ciudad Real",  
        image: "acido_ciudad_real.jpg",      
        description:
          "El <strong>ácido hialurónico</strong> es una molécula natural presente en nuestro organismo que desempeña un papel fundamental en la hidratación y lubricación de los tejidos. Se caracteriza por su capacidad única para retener grandes cantidades de agua, lo que le confiere propiedades hidratantes y voluminizadoras. Utilizado en el campo de la medicina estética, el ácido hialurónico se inyecta en la piel para corregir arrugas y pliegues, restaurar el volumen perdido y mejorar la apariencia de la piel.",
      },
      {
        id: 1,        
        title: "Botox",  
        alt: "Botox Ciudad Real",  
        image: "botox_ciudad_real.jpg",      
        description:
          "El <strong>Botox</strong>, abreviación de la toxina botulínica, es un tratamiento estético no quirúrgico utilizado para reducir temporalmente las arrugas y líneas de expresión en el rostro. \n Se aplica mediante pequeñas inyecciones en áreas seleccionadas, como la frente, el entrecejo y alrededor de los ojos, para relajar los músculos responsables de las arrugas dinámicas. Al inhibir temporalmente la contracción muscular, se logra un aspecto más suave y rejuvenecido de la piel. \n El procedimiento es rápido, generalmente indoloro y no requiere tiempo de recuperación significativo. ",
      },
      {
        id: 2,        
        title: "Micropigmentación capilar",    
        alt: "Micropigmentación capilar Ciudad Real",      
        image: "micropigmentacion_ciudad_real.jpg",
        description:
          "Es una técnica que se utiliza en pacientes que por su zona donante no son aptos para trasplante capilar, para dar densidad capilar donde hay falta de pelo, tanto en hombres como en mujeres, para hacer un efecto rapado en personas que están más afectadas por la alopecia, para camuflar cicatrices, cubrir zonas donantes después de un injerto y para acompañar a una cirugía de trasplante capilar donde no podemos conseguir el efecto deseado por el paciente. \n Este tratamiento se hace mediante unas microiyecciones de pigmento en la capa más superficial de la dermis, suele ir desvaneciéndose a partir de los 12 meses, y puede durar más o menos entre 1 y 3 años, según la piel de cada paciente. \n Es un tratamiento prácticamente indoloro, a veces se siente solo una molestia. \n Este tratamiento no está indicado para los pacientes que tengan un grado avanzado de alopecia si se pretende llevar el cabello medio-largo.",
      },
      {
        id: 3,        
        title: "Microblading",    
        alt: "Microblading Ciudad Real",      
        image: "microblading_ciudad_real.jpg",
        description:
          "El <strong>microblading </strong> es una técnica de maquillaje semipermanente para las cejas que proporciona un aspecto natural y definido. Consiste en utilizar una pluma con pequeñas cuchillas en la punta para crear trazos finos que imitan los vellos de las cejas. Estos trazos se realizan en la capa superior de la piel, depositando pigmento para rellenar y dar forma a las cejas de manera precisa. El microblading es ideal para corregir cejas escasas, poco definidas o con áreas despobladas, permitiendo lograr cejas simétricas y con apariencia más densa. Los resultados suelen durar aproximadamente de uno a tres años, dependiendo del cuidado y mantenimiento. Es una opción popular para aquellos que desean mejorar la apariencia de sus cejas de forma natural y duradera.",
      },
    
    ];
    const features = [
      {
        id: 1,        
        title: "",
        alt: "mesoterapia Ciudad Real",      
        image: "mesoterapia_ciudad_real.jpg",
        description:
          "La Mesoterapia capilar es un <strong>tratamiento para el pelo</strong> que, mediante la aplicación subcutánea de microinyecciones , prácticamente indoloras, activan las células capilares. Este procedimiento revierte la miniaturización del folículo, lo que incrementa el espesor y aumento del cabello. No hace falta llevar ningún cuidado precio, la técnica dura unos 15/20 minutos y no deja ninguna marca. Podrás hacer vida normal inmediatamente después del tratamiento. Normalmente se infiltra cada 3 meses, los resultados los obtendremos entre los 6 y los 12 meses y se alargará en el tiempo según el caso de cada paciente. Existen diferentes tipos de <strong> mesoterapia capilar </strong>, adaptándose cada una de ellas a las necesidades de cada paciente:",
      },
      // {
      //   id: 2,        
      //   title: "Tipos de mesoterapia",
      //   image: "",
      //   description:
      //     "Existen diferentes tipos de mesoterapia capilar que se adaptan a las necesidades específicas de cada persona. A continuación, se presentan algunos de los tipos más comunes de mesoterapia capilar:\n\n",
      // },
      {
        id: 3,        
        title: "Mesoterapia con vitaminas:",
        image: "",
        description:
          "Este tipo de mesoterapia capilar utiliza una combinación de vitaminas, minerales y otros nutrientes 🌿 para fortalecer los folículos capilares, estimular el crecimiento del cabello y mejorar la circulación sanguínea en el cuero cabelludo. Se administran pequeñas inyecciones en el área afectada.",
      },
      {
        id: 4,        
        title: "Mesoterapia con PRP (Plasma rico en plaquetas):",
        image: "",
        description:
          "En este tipo de mesoterapia capilar, se utilizan factores de crecimiento obtenidos de la propia sangre del paciente 💉. Estos factores de crecimiento ayudan a regenerar los folículos capilares, promoviendo así el crecimiento de cabello nuevo y saludable.",
      },
      {
        id: 5,        
        title: "Mesoterapia para la revitalización integral del folículo:",
        image: "",
        description:
          "Los péptidos son cadenas de aminoácidos que estimulan la producción de colágeno y elastina en el cuero cabelludo 💆‍♀️. Este tipo de mesoterapia capilar ayuda a fortalecer los folículos capilares y mejorar la calidad del cabello.",
      },
      {
        id: 6,        
        title: "Mesoterapia con nuestra fórmula magistral:",
        image: "",
        description:
          "En algunos casos, se pueden utilizar medicamentos específicos, como minoxidil, finasterida o dutasterida, en combinación con la mesoterapia capilar para tratar la caída del cabello y promover su crecimiento 💊.",
      },
      {
        id: 7,        
        title: "Bioestimulacion con microneedling (Dermapen):",
        image: "",
        description:
          "La mesoterapia con microneedling es un tratamiento capilar no invasivo que combina microagujas con la aplicación de nutrientes en el cuero cabelludo 💆‍♂️. Estimula la producción de colágeno, fortalece los folículos capilares y promueve el crecimiento de cabello nuevo y saludable. Es eficaz para tratar problemas capilares como la <strong> caída del cabello </strong> y el adelgazamiento, y se realiza de forma segura y con resultados visibles en pocas sesiones.",
      },
     
    ];
    const images = [
      { id: 1, name: "gallery-image-1.jpg" },
      { id: 2, name: "gallery-image-2.jpg" },
      { id: 3, name: "gallery-image-3.jpg" },
      { id: 4, name: "gallery-image-4.jpg" },
      { id: 5, name: "gallery-image-5.jpg" },
      { id: 6, name: "gallery-image-6.jpg" },
    ];
    const offers = [
      { id: 1, name: "offer-1.jpg" },
      // { id: 2, name: "gallery-image-2.jpg" },
      // { id: 3, name: "gallery-image-3.jpg" },
      // { id: 4, name: "gallery-image-4.jpg" },
      // { id: 5, name: "gallery-image-5.jpg" },
      // { id: 6, name: "gallery-image-6.jpg" },
    ];
    const menu = [
      { id: 1, title: "Inicio", link: "/home"},
      { id: 3, title: "Transplante capilar", link: "/capilar"},
      { id: 2, title: "Tratamientos capilares", link: "/tratamientos-capilares"},     
      { id: 4, title: "Medicina Estética", link: "/medicina-estetica"},     
      // { id: 4, title: "Galería", link: "/gallery"},
      // { id: 5, title: "Promociones", link: "/offers"},      
      // { id: 6, title: "Precios", link: "/pricing"},
      { id: 7, title: "Opiniones", link: "/testimonials"},      
      // { id: 8, title: "Política de Privacidad", link: "/privacy-policy"},
      { id: 9, title: "Contacto", link: "/contact"},
    ];
    const companies = [
      {
        id: 1,
        name: "Tree",
        weblink: "company-logo1.png",
        logo: "company-logo1.png",
      },
      {
        id: 2,
        name: "Fingerprint",
        weblink: "company-logo2.png",
        logo: "company-logo2.png",
      },
      {
        id: 3,
        name: "The Man",
        weblink: "company-logo3.png",
        logo: "company-logo3.png",
      },
      {
        id: 4,
        name: "Mustache",
        weblink: "company-logo4.png",
        logo: "company-logo4.png",
      },
      {
        id: 5,
        name: "Goat",
        weblink: "company-logo5.png",
        logo: "company-logo5.png",
      },
      {
        id: 6,
        name: "Justice",
        weblink: "company-logo6.png",
        logo: "company-logo6.png",
      },
      {
        id: 7,
        name: "Ball",
        weblink: "company-logo7.png",
        logo: "company-logo7.png",
      },
      {
        id: 8,
        name: "Cold",
        weblink: "company-logo8.png",
        logo: "company-logo8.png",
      },
      {
        id: 9,
        name: "Cold",
        weblink: "company-logo9.png",
        logo: "company-logo9.png",
      },
    ];
    const feedback = [
      {
        id: 1,
        name: "Juan Carlos Fresno Donado",
        userimage: "user-1.jpg",
        comments:
          "Gran equipo de profesionales y atención muy cuidada y con gran seguimiento caso a caso. Cualquier aspecto estético puedes tratarlo con ellos con plena confianza. 100% recomendable.",
        company: "",
      },
      {
        id: 2,
        name: "Maria Teresa Marin Cerrillo",
        userimage: "user-2.jpg",
        comments:
          "Clínica estupenda, grandes profesionales y exquisito el trato.",
        company: "Happy Customer",
      },
      {
        id: 3,
        name: "InfoAyuda",
        userimage: "user-3.jpg",
        comments:
          "Grandes profesionales en su labor. Te atienden de maravilla antes,durante y luego de  la intervención.",
        company: "Happy Customer",
      },
      {
        id: 4,
        name: "PEDRO JESUS GIL GARCIA",
        userimage: "user-4.jpg",
        comments:
          "Excelente equipo de profesionales, tanto técnicamente como en la atención y tratamiento a los pacientes. Espectaculares resultados. Recomendables 100%.",
        company: "Happy Customer",
      },
      {
        id: 5,
        name: "Luis Jesús Rodríguez Buendía",
        userimage: "user-5.jpg",
        comments:
          "Magnífico trato y profesionalidad, totalmente recomendable.",
        company: "Happy Customer",
      },
      {
        id: 6,
        name: "Paula Poblador Fuente",
        userimage: "user-6.jpg",
        comments:
          "Excelente clínica,con muy buenos tratamientos y mejores resultados.",
        company: "Happy Customer",
      },
      {
        id: 7,
        name: "Ricardo Valladares",
        userimage: "user-7.jpg",
        comments:
          "Excelente servicio y profesionalismo. Tuve una experiencia de lo mejor que me ha pasado me informaron de todo lo que necesitaba para hacer mi trasplante capilar ,y ya tengo fecha para mi cirugía muy recomendada a todos los niveles ,fantástica atención y resultados.",
        company: "Happy Customer",
      },
      {
        id: 8,
        name: "Yer",
        userimage: "user-8.jpg",
        comments:
          "Servicio de calidad y además asequible. Ya no es necesario irse a Turquía :P. Muchas gracias por la atención!",
        company: "Happy Customer",
      },
      {
        id: 9,
        name: "Sergio M.L",
        userimage: "user-9.jpg",
        comments:
          "Profesionales absolutos. Un trato inmejorable y unos resultados sublimes. Los recomiendo cien por cien.",
        company: "Happy Customer",
      },
      {
        id: 10,
        name: "87dmluna",
        userimage: "user-10.jpg",
        comments:
          "Sin duda el mejor sitio para ir. Muy profesionales, los mejores.",
        company: "Happy Customer",
      },
      {
        id: 11,
        name: "Henry Moreno",
        userimage: "user-11.jpg",
        comments:
          "Muy profesionales. Te explican cada paso el proceso. Recomendados 100%.",
        company: "Happy Customer",
      },
      {
        id: 12,
        name: "Amparo Castillo",
        userimage: "user-12.jpg",
        comments:
          "Muy profesionales, exquisitos en su atención ( y en los resultados🤗) 👍, los recomiendo el 100%,..",
        company: "Happy Customer",
      },
      {
        id: 13,
        name: "Vivian Gomez",
        userimage: "user-13.jpg",
        comments:
          "Excelentes profesionales y magnífica atención.",
        company: "Happy Customer",
      },
      {
        id: 14,
        name: "Aliany Cuevas Abreu",
        userimage: "user-12.jpg",
        comments:
          "Fuimos a una consulta y el trato fue maravilloso... gracias!",
        company: "Happy Customer",
      },
      {
        id: 15,
        name: "Ruandy Rodríguez Barceló",
        userimage: "user-15.jpg",
        comments:
          "Trato 5 estrellas lo recomiendo 100%",
        company: "Happy Customer",
      },
      {
        id: 16,
        name: "Rafael Gomez",
        userimage: "user-16.jpg",
        comments:
          "The clinic is a very clean, impeccable place, top notch. Customer service is outstanding from its workers. The professionals who work there are well prepared and highly professional in what they do. I recommend this clinic without a doubt.",
        company: "Happy Customer",
      },
      {
        id: 17,
        name: "Yoandy Nodarse Mendez",
        userimage: "user-17.jpg",
        comments:
          "The best professionals in the whole country, I do recommend this business.",
        company: "Happy Customer",
      },
      {
        id: 18,
        name: "Aliandy de la Concepción",
        userimage: "user-18.jpg",
        comments:
          "Atención de lujo y calidad garantizada",
        company: "Happy Customer",
      },
      {
        id: 19,
        name: "Cesar Perez",
        userimage: "user-19.jpg",
        comments:
          "Perfecto trato personal y profesional",
        company: "Happy Customer",
      },
      {
        id: 20,
        name: "Nuria Toribio carbayo",
        userimage: "user-20.jpg",
        comments:
          "Espectacular!!! En una sola palabra.",
        company: "Happy Customer",
      },
      {
        id: 21,
        name: "Edel Morales Gomez",
        userimage: "user-21.jpg",
        comments:
          "Excelencia 👌👌👌 …",
        company: "Happy Customer",
      },
      {
        id: 22,
        name: "Pablo Alejandro Alfonso Camacho",
        userimage: "user-22.jpg",
        comments:
          "👌🏻 …",
        company: "Happy Customer",
      },
      {
        id: 23,
        name: "Daniel Gallo",
        userimage: "user-23.jpg",
        comments:
          "Excelente clínica. Una atención muy profesional. Recomendable 100%",
        company: "Happy Customer",
      },
      {
        id: 24,
        name: "Luis Alberto Jiménez Martínez",
        userimage: "user-24.jpg",
        comments:
          "5 stars",
        company: "Happy Customer",
      },
      {
        id: 25,
        name: "Nuria Cerro",
        userimage: "user-25.jpg",
        comments:
          "El personal es muy agradable y dan muy buen trato. El doctor que me atendió me explicó todo muy claro y me dijo las diferentes opciones que había para mí problema.  Note los resultados de las inyecciones desde la primera consulta. Lo recomiendo",
        company: "Happy Customer",
      },
      {
        id: 26,
        name: "Vicente Garcia",
        userimage: "user-26.jpg",
        comments:
          "Un gran equipo altamente profesional y humano que ofrece un servicio espectacular a sus pacientes. Su enfoque en la calidad del trato y la atención personalizada es muy cercano, con muy buen asesoramiento buscando soluciones capilares. El personal demuestra empatía y comprensión, creando un ambiente acogedor que contribuye al bienestar de los pacientes. Un resultado inmejorable.",
        company: "Happy Customer",
      },
      {
        id: 27,
        name: "Raúl Martín",
        userimage: "user-27.jpg",
        comments:
          "Estupenda experiencia en tratamiento capilar. Todo el equipo es muy amable y comprometido, buscando las mejores opciones para cada cliente. La recomiendo!",
        company: "Happy Customer",
      },
      {
        id: 28,
        name: "Natalia Gutierrez Adán",
        userimage: "user-28.jpg",
        comments:
          "Encantada con el trato muy buenas profesionales, muy contenta con mi tratamiendo , volveré sin duda",
        company: "Happy Customer",
      },
      {
        id: 29,
        name: "Daniel Martínez",
        userimage: "user-29.jpg",
        comments:
          "Magnífica atención de todo el equipo que me atendió, mejor imposible. Resultado muy satisfactorio, mejor de lo inicialmente esperado. Daniel es un crack.",
        company: "Happy Customer",
      },
      {
        id: 30,
        name: "Alex Piero",
        userimage: "user-30.jpg",
        comments:
          "👏👏👏",
        company: "Happy Customer",
      },
    ];
    const plans = [
      {
        id: 1,
        title: "PERSONAL",
        subtitle: "The standard version",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        price: "19",
        currency: "₹",
        downloads: "5 Downloads",
        extensions: "2 Extensions",
        tutorials: "Tutorials",
        support: "Forum Support",
        updates: "1 year free updates",
        buttontext: "BUY TODAY",
        buttonlink: "#",
        featured: false,
      },
      {
        id: 2,
        title: "STUDENT",
        subtitle: "Most popular choice",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        price: "29",
        currency: "₹",
        downloads: "15 Downloads",
        extensions: "5 Extensions",
        tutorials: "Tutorials with files",
        support: "Forum Support",
        updates: "2 year free updates",
        buttontext: "BUY TODAY",
        buttonlink: "#",
        featured: true,
      },
      {
        id: 3,
        title: "BUSINESS",
        subtitle: "For the whole team",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        price: "49",
        currency: "₹",
        downloads: "Unlimited Downloads",
        extensions: "Unlimited Downloads",
        tutorials: "HD Video Tutorials",
        support: "Chat Support",
        updates: "Lifetime free updates",
        buttontext: "BUY TODAY",
        buttonlink: "#",
        featured: false,
      },
    ];
    const websites = [
      {
        id: 1,
        link: "https://facebook.com/",
        title: "Facebook",
        target: "_blank",
        username: "profile.php?id=100092039029013",
        icon: "facebook",
      },
      // {
      //   id: 2,
      //   link: "https://googleplus.com/",
      //   title: "Google+",
      //   target: "_blank",
      //   username: "+jagmohan",
      //   icon: "google-plus",
      // },
      // {
      //   id: 3,
      //   link: "https://twitter.com/",
      //   title: "Twitter",
      //   target: "_blank",
      //   username: "joker",
      //   icon: "twitter",
      // },
      {
        id: 4,
        link: "https://instagram.com/",
        title: "Instagram",
        target: "_blank",
        username: "steticap",
        icon: "instagram",
      }, 
      {
        id: 5,
        link: "https://www.linkedin.com/in/steticap-sl-8b76b5283/",
        title: "Linkedin",
        target: "_blank",
        username: "",
        icon: "linkedin",
      },    
    ];

    return {
      menu,
      pages,
      features,
      images,
      offers,
      companies,
      feedback,
      plans,
      websites,
      homeitems,
      featuresEstetica
    };
  }
}
