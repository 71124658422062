import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutPageComponent } from '../about/about-page/about-page.component';
import { AboutRoutingModule } from '../about/about-routing.module';
import { FormsModule } from '@angular/forms';
import { FeatureBlockComponent } from '../about/feature-block/feature-block.component';
import { SharedFeatureBlockModule } from './shared-feature-block.module';
import { SharedContactFormModule } from './sharedContactForm.module';


@NgModule({
  declarations: [AboutPageComponent],
  exports: [AboutPageComponent],
  imports: [CommonModule, AboutRoutingModule, FormsModule, SharedFeatureBlockModule, SharedContactFormModule],
})
export class SharedAboutPageModule { }
