import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import emailjs from 'emailjs-com';
import { ToastrService } from 'ngx-toastr';
import AOS from "aos";

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  name: string = "";
  email: string = "";
  message: string = "";
  phone: string  = "";
  captcha: string  = "";  
  consentimiento: boolean;
  

  constructor(private http: HttpClient, private toastr: ToastrService) {     
  }
  ngOnInit(): void {
    AOS.init();
  }
  

  submitForm() {    
    if (this.name.trim() === '' || this.phone.trim() === '') {
      this.toastr.error("Por favor, complete los campos obligatorios (Nombre y Número de móvil).", 'Error de validación');
      return;
    }

    if(!this.IsPhoneNumberValid(this.phone.trim()))
    {
      this.toastr.error("Por favor, ingresa un número de teléfono válido de 9 dígitos", 'Error de validación');
      return;
    }
    
    const templateParams = {
      name: this.name,
      email: this.email,
      message: this.message,
      phone: this.phone
    };
  
    emailjs.send('SteticapEmailJS', 'template_eo6ndqg', templateParams, 'Ix1CdmtwL6WK8WfAF')
      .then(() => {
        console.log('Email sent successfully');
        // Handle success
      }, (error) => {
        console.log('Failed to send email', error);
        // Handle error
      });

   // Show the toast notification
   console.log("antes de mostrar mensaje ")
   this.toastr.success('Su consulta se ha enviado correctamente', 'Éxito', {
    closeButton: true,
    positionClass: 'toast-bottom-center' ,
    timeOut: 5000 // duration
  });

    this.resetForm();
  }

  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;    
}

  
  resetForm() {
    this.name = '';
    this.email = '';
    this.message = '';
    this.phone = '';
  }

  IsPhoneNumberValid(telefono:string) {
    console.log("telefono"+ telefono);
    // Expresión regular para validar números de teléfono en formato español
    //const regex = /^(\+?\d{2}|\d{2}|)[6-9]\d{8}$/;    
    //const regex = /^(?:\+|00)?[6-9]\d{8}$|^[6-9]\d{2}-\d{2}-\d{2}-\d{2}$/;
    //const regex = /^(?:\+|00)?[0-9]\d{8}$|^[0-9]\d{2}-\d{2}-\d{2}-\d{2}$/;
    const regex = /^(?:(?:\+|00)34|0)?[6-9]\d{8}$|^[6-9]\d{2}-\d{2}-\d{2}-\d{2}$/;

    return regex.test(telefono);
  }
}
