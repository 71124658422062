import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServicesRoutingModule } from './services-routing.module';
import { ServicesPageComponent } from './services-page/services-page.component';
import { AccordionComponent } from '../accordion/accordion.component';
import { SharedContactFormModule } from '../shared/sharedContactForm.module';


@NgModule({
  declarations: [
    ServicesPageComponent, AccordionComponent
  ],
  imports: [
    CommonModule,
    ServicesRoutingModule,
    SharedContactFormModule
  ]
})
export class ServicesModule { }
