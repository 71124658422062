import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import AOS from "aos";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-test-home',
  templateUrl: './test-home.component.html'  
})
export class TestHomeComponent implements OnInit {

  videoUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
    const videoId = 'yU4v9A0TNrs'; // Reemplaza con el ID de tu video de YouTube
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${videoId}?iv_load_policy=0&disablekb=1&modestbranding=1&fs=0&rel=0&autoplay=1&loop=1&mute=1&showinfo=0&controls=0&frameborder=0&playlist=${videoId}`);
  }
  ngOnInit(): void {
    AOS.init();

    
  }

  videoError(event: Event) {
    console.error('Error loading or playing video:', (event.target as HTMLVideoElement).error);
    // Handle error gracefully (e.g., show alternate content, retry loading, etc.)
}

}
