import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactFormComponent } from '../contact-form/contact-form.component';
import { ContactFormRoutingModule } from '../contact-form/contact-form-routing.module';
import { FormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';


@NgModule({
  declarations: [ContactFormComponent],
  exports: [ContactFormComponent],
  imports: [CommonModule, ContactFormRoutingModule, FormsModule,  RecaptchaModule],
})
export class SharedContactFormModule { }
