import { Observable } from 'rxjs';
import { Component, Input, OnInit } from "@angular/core";
import { ConfigService } from "src/app/shared/services/config.service";

@Component({
  selector: "app-about-page",
  templateUrl: "./about-page.component.html"
})
export class AboutPageComponent implements OnInit {
  // intro: {
  //   id: number;
  //   name: string;
  //   tagline: string;
  //   title: string;
  //   description: string;
  // }[];
  intro$: Observable<any> = new Observable();
  features$: Observable<any> = new Observable();

  // features: {
  //   id: number;
  //   icon: string;
  //   title: string;
  //   description: string;
  // }[];

  constructor(private config: ConfigService) {}
  @Input() featureName: string = "features";
  @Input() homeId: number = 1;


  ngOnInit(): void {
    this.getPageData("pages", this.homeId);
    this.getBlockData(this.featureName);
  }

  getPageData(database: string, id?: number): void {
    this.intro$ = this.config.getSettings(database, id);
  }

  getBlockData(database: string) {   
    this.features$ = this.config.getSettings(database);

    this.features$.subscribe((value) => {
      // Here, 'value' represents the real emitted value from the Observable      
    });
  }
}
