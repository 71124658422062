import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestHomeRoutingModule } from './test-home-routing.module';
import { TestHomeComponent } from './test-home.component';
import { ContactFormModule } from "../contact-form/contact-form.module";
// import { SharedContactFormModule } from "../shared/sharedContactForm.module";
import { HomeinformationComponent } from "../homeinformation/homeinformation.component";
import { AboutUsComponent } from "../about-us/about-us.component";
import { ContactFormComponent } from "../contact-form/contact-form.component";
import { SharedContactFormModule } from '../shared/sharedContactForm.module';

@NgModule({
  declarations: [ TestHomeComponent,  HomeinformationComponent, AboutUsComponent],
  imports: [CommonModule,   TestHomeRoutingModule, SharedContactFormModule],
})
export class TestHomeModule { }
