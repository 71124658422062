import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "offers-app-image-block",
  templateUrl: "./offers-image-block.component.html"
})
export class OffersImageBlockComponent implements OnInit {
  @Input() image: { id: number; name: string };

  constructor() {}

  ngOnInit() {}
}
