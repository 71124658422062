import { Component } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css']
})
export class AccordionComponent {
  items = [
    {
      title: '¿Qué es y por qué realizar un trasplante capilar?',
      content: 'El trasplante capilar es un procedimiento quirúrgico que se realiza para solucionar la pérdida de cabello y restaurar la densidad capilar, mejorando la apariencia estética y la confianza de las personas afectadas..',
      expanded: false
    },
    {
      title: '¿Cómo se realiza el procedimiento?',
      content: 'El procedimiento de microinjerto capilar, implica la extracción de unidades foliculares de la zona donante para su posterior implantación (una a una)  en el área rectora, utilizando técnicas como la extracción de unidades foliculares (FUE).',
      expanded: false
    },
    {
      title: '¿De dónde se extraen los folículos, tipos y grados de alopecia, cual es un candidato apto o no apto para trasplante capilar?',
      content: 'Los folículos capilares se extraen generalmente de la zona donante, que es una región de la cabeza con cabello sano y resistente a la caída. Los tipos de alopecia son: androgénica , areata, fibrosante, cicatricial y universal, siendo la más común la androgénica. Y los grados de alopecia se suelen identificar según la escala de Nowood Hamilton, diferenciando 6 grados de esta enfermedad crónica. En general, aquellos con alopecia androgenética estable y suficiente cantidad de folículos en la zona donante son buenos candidatos para el trasplante capilar, mientras que aquellos con alopecia avanzada o condiciones médicas que afectan a los folículos pueden no ser aptos. Nostros valoramos tu caso.',
      expanded: false
    },
    {
      title: '¿Cuánto dura una cirugía de trasplante capilar?',
      content: 'Entre 6 y 8 horas.',
      expanded: false
    },
    {
      title: '¿Qué época es mejor para realizarlo?',
      content: 'En general, muchos especialistas en trasplante capilar recomiendan realizar el procedimiento durante los meses de otoño e invierno. Durante esta época, es menos probable que se exponga el cuero cabelludo trasplantado a la luz solar directa, lo que puede ayudar en la cicatrización y protección de los folículos trasplantados. No habiendo ningún problema en realizar el trasplante capilar en primavera - verano, siguiendo las pautas del equipo especialista.',
      expanded: false
    },
    {
      title: ' ¿Qué hay que tener en cuenta para el preoperatorio?',
      content: 'Para el preoperatorio de un trasplante capilar, es importante seguir las instrucciones del cirujano capilar, que pueden incluir evitar medicamentos anticoagulantes, dejar de fumar, evitar el consumo de alcohol o drogas y cafeína, no hacer ejercicio los días previos y mantener una buena higiene capilar.',
      expanded: false
    },
    {
      title: '¿Cómo es el postoperatorio?',
      content: 'El postoperatorio de un trasplante capilar suele ser muy agradecido, no obstante es común experimentar inflamación, enrojecimiento y costras en el área receptora, además de sentir algunas molestias leves. Se deben seguir las indicaciones del cirujano capilar, como dormir un poco incorporado, tener el injerto bien humedecido, evitar el sol, el ejercicio intenso, llevar casco o gorra y el uso de productos capilares durante los primeros días, y se deben realizar cuidados de limpieza y seguimiento adecuados para una óptima recuperación, además de tratamiento y medicación.',
      expanded: false
    },
    {
      title: '¿En cuánto tiempo veré resultados?',
      content: 'Los resultados del trasplante capilar comienzan a ser visibles a partir de los 3 a 6 meses posteriores al procedimiento, pero el crecimiento completo y los resultados finales pueden tomar entre 9 a 12 meses.',
      expanded: false
    },    
    {
      title: '¿Qué ocurre después de un injerto?',
      content: 'Después de un injerto capilar, el cabello trasplantado pasará por una fase de caída temporal conocida como "efluvio telógeno" o el conocido "shock loss" en las primeras semanas o meses. Luego, comenzará a crecer nuevamente de forma permanente en su nueva ubicación, y se espera que los resultados finales sean visibles después de un año.',
      expanded: false
    },
    {
      title: '¿Cuanto pelo me vais a poner?¿Hablamos de unidades foliculares o de folículos?¿Cuanto tiempo duran las unidades foliculares fuera del organismo?¿Cómo las conservamos?',
      content: '- La cantidad de pelo que pondremos variará del grado y del tipo de alopecia, del estado de la zona donante y de la calidad de los folículos extraídos.  \n- Cada unidad folicular suele tener de 1 a 5 folículos, por lo que nosotros SIEMPRE nos referimos a unidades foliculares y no a "pelos". \n - Las unidades foliculares duran fuera del cuerpo , en condiciones óptimas de conservación, entorno a 6 horas.\n - Siempre Refrigeradas y humedecidas con diferentes soluciones.',
      expanded: false
    },
    {
      title: '¿Técnicas de implantación?',
      content: 'En Steticap te garantizamos el mejor resultado con las técnicas de implantación FUE y DHI, con rasurado o sin rapar, tanto en hombres como en mujeres.',
      expanded: false
    },
  ];

  toggleAccordion(index: number) {
    this.items[index].expanded = !this.items[index].expanded;
  }
}
