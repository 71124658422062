import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TratamientosEsteticaRoutingModule } from './tratamientos-estetica-routing.module';
import { SharedContactFormModule } from '../shared/sharedContactForm.module';
import { AboutPageComponent } from '../about/about-page/about-page.component';
import { SharedAboutPageModule } from '../shared/shared-about-page.module';
import { SharedFeatureBlockModule } from '../shared/shared-feature-block.module';
import { EsteticaMainPageComponent } from './estetica-main-page/estetica-main-page.component';


@NgModule({
  declarations: [    
  
    EsteticaMainPageComponent
  ],
  imports: [
    CommonModule,
    TratamientosEsteticaRoutingModule,
    SharedContactFormModule,
    SharedAboutPageModule,
    SharedFeatureBlockModule
  ]
})
export class TratamientosEsteticaModule {

 }
